import styled from 'styled-components';
import About from './$Home/AboutSection';
import Hero from './$Home/Hero';
import Catering from './$Home/Catering';
// import ReservationWidget from 'components/ReservationWidget';

// import AboutSection from './Home/AboutSection.js';

// import Catering from './Home/Catering.js';

// import Hero from './Home/Hero.js';

const Home = () => {
  return (
    <>
      <Page>
        <PageHeader>
          <Hero />
        </PageHeader>
        <PrimaryContentArea>
          {/* <ReservationWidget  /> */}
          <About />
          <Catering />
        </PrimaryContentArea>
      </Page>
    </>
  );
};

const Page = styled.main``;

const PageHeader = styled.div`
  width: 100%;
`;

const PrimaryContentArea = styled.div`
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
`;

export default Home;
