import React, { useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import styled from 'styled-components';

const Categories = ({ categories, filterItems, defaultCategory }) => {
  // const defaultCategory =
  //   categories.find(category => category.toLowerCase() === 'pizza') ||
  //   categories[0];
  const [active, setActive] = useState(defaultCategory);

  const handleClick = category => {
    setActive(category);
    filterItems(category);
  };

  return (
    <ButtonContainer>
      <ButtonWrapper>
        {categories.map((category, index) => {
          return (
            <FilterButton
              className={category === active ? 'active' : ''}
              type='button'
              key={index}
              onClick={() => handleClick(category)}
            >
              {category}
            </FilterButton>
          );
        })}
      </ButtonWrapper>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.nav`
  padding: 1rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 6px;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: 768px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const FilterButton = styled.button`
  margin-top: 2rem;
  /* margin-right: 0.1rem; */
  background: transparent;
  border-color: transparent;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.accentColor2};
  padding: 0 5px 1rem 5px;
  font-size: 1.05rem;

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
  }
`;

export default Categories;
