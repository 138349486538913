import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useAnalytics from 'hooks/useAnalytics';

import logo from 'assets/mikla_transparent_logo.png';
import heroImg1 from 'assets/images/hero-img-1.jpg';
import heroImg2 from 'assets/images/hero-img-2.jpg';
import takeawayMenu from 'assets/mikla_takeaway_meny_2024.pdf';
import dineInMenu from 'assets/mikla_meny_2024.pdf';

const Hero = () => {
  const { sendEvent } = useAnalytics();

  const handleClick = () => {
    sendEvent({
      category: 'navigation',
      action: 'click',
      label: 'sidebar_burger',
    });
  };

  return (
    <HeroSection>
      <ImageSection>
        <div className="image-container1">
          <img src={heroImg2} alt="grilled plate" className="image1" />
          <HeroCard>
            <h1>Grill & Meze</h1>

            {/* <Link to="/meny">
              <button onClick={() => handleClick("hero_button_grill")}>
                meny
              </button>
            </Link> */}

            <MenuLink href={dineInMenu} target="_blank" rel="noreferrer">
              Meny
            </MenuLink>

            <PhoneNumber
              onClick={() => handleClick('hero_phone_grill')}
              href="tel:018-508800"
            >
              018-508800
            </PhoneNumber>
          </HeroCard>
        </div>
        <LogoWrapper>
          <Logo src={logo} />
        </LogoWrapper>
        <div className="image-container2">
          <img src={heroImg1} alt="pizza" className="image2" />
          <HeroCard>
            <h1>Pizzabutik</h1>
            {/* <Link to='/takeaway'>
              <button onClick={() => handleClick('hero_button_pizza')}>
                meny
              </button>
            </Link> */}
            <MenuLink href={takeawayMenu} target="_blank" rel="noreferrer">
              Meny
            </MenuLink>
            <PhoneNumber
              onClick={() => handleClick('hero_phone_pizza')}
              href="tel:018-250060"
            >
              018-250060
            </PhoneNumber>
          </HeroCard>
        </div>
      </ImageSection>
    </HeroSection>
  );
};

const HeroSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media screen and (orientation: portrait) {
    height: calc(100vh - 56px);
  }

  @media screen and (min-width: 768px) {
    height: 100vh;
  }
`;

const MenuButton = styled.a``;

const MenuLink = styled.a`
  padding: 12px 60px;
  border: none;
  border-radius: 5px;
  font-size: clamp(1rem, 2vw, 2rem);
  cursor: pointer;
  margin-bottom: 16px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textColor};

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;

  @media screen and (min-width: 1025px) {
    flex-direction: row;
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) {
    flex-direction: row;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .image-container1,
  .image-container2 {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .image1,
  .image2 {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  button {
    padding: 12px 60px;
    border: none;
    border-radius: 5px;
    font-size: clamp(1rem, 2vw, 2rem);
    cursor: pointer;
    margin-bottom: 16px;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;

    &:hover {
      transition: all 0.3s ease-in-out;
      transform: scale(1.05);
    }
  }

  h1 {
    font-size: clamp(2.3rem, 6vw, 3.5rem);
    color: ${({ theme }) => theme.colors.textColor};
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 300;
  }

  @media screen and (min-width: 200px) and (orientation: landscape) {
    h1 {
      font-size: 2rem;
    }
  }
`;
const PhoneNumber = styled.a`
  font-size: clamp(1rem, 1.3vw, 1.5rem);
  color: ${({ theme }) => theme.colors.textColor};
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const Logo = styled.img`
  width: 20vw;
  max-width: 80px;

  @media screen and (min-width: 1000px) {
    max-width: 120px;
  }
`;

const HeroCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 10px;

  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%; /* Adjust the card width as needed */
  max-width: 300px;
`;

export default Hero;
