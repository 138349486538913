import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const useAnalytics = () => {
  const sendEvent = event => {
    const userDecision = localStorage.getItem('accepted');
    if (userDecision === 'accepted') {
      ReactGA.event(event);
    }
  };

  return { sendEvent };
};

export default useAnalytics;
