import React, { useState } from 'react';
import MenuItems from 'components/MenuComponent/DineInItems/DineInItems';
import Categories from 'components/MenuComponent/Categories';
import items from '../components/MenuComponent/DineInItems/data';
// import Underline from '../../styles/GlobalElements';
import { Helmet } from 'react-helmet';

import {
  MenuContainer,
  MenuWrapper,
  MenuHeader,
  Headline,
  ArrowBack,
  BackLink,
} from 'components/MenuComponent/StyledMenuElements';

const allCategories = ['alla', ...new Set(items.map(item => item.category))];

const Menu = () => {
  const [menuItems, setMenuItems] = useState(items);
  const [categories, setCategories] = useState(allCategories);

  const filterItems = category => {
    if (category === 'alla') {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter(item => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <>
      <Helmet>
        <meta name='pageName' content='Dine In Page' />
        <title>Servering</title>
      </Helmet>
      <MenuContainer>
        <MenuWrapper>
          <MenuHeader>
            <Headline>Serveringsmeny</Headline>
          </MenuHeader>
          {/* <Underline /> */}
          <Categories
            categories={categories}
            filterItems={filterItems}
            defaultCategory='meze'
          />
          <MenuItems items={menuItems} />
        </MenuWrapper>
      </MenuContainer>
    </>
  );
};

export default Menu;
