import React from 'react';
import Modal from 'components/Modal';
import styled from 'styled-components';
import ContactForm from './ContactForm';

const ModalContactDiv = ({ handleClose }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModalOpen = e => {
    setIsModalOpen(!isModalOpen);
    e.preventDefault();
  };

  const handleModalClose = () => setIsModalOpen(false);

  return (
    <>
      <MainContent>
        <a onClick={handleModalOpen}>
          Built and designed by <span>Ö</span>zgür
        </a>
      </MainContent>
      <ContactDivContainer className='ModalContactDiv'>
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <ContactForm handleClose={handleClose} />
        </Modal>
      </ContactDivContainer>
    </>
  );
};

const ContactDivContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999;
  bottom: 0;
  width: 100%;
  overflow: auto;
  background-color: #487a80;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #487a80;

  a {
    font-size: 8px;
    /* font-family: 'Bruno Ace SC', cursive; */
    font-family: 'Press Start 2P', cursive;
    padding: 15px 0;
    color: ${({ theme }) => theme.colors.textColor};
  }

  span {
    font-size: 10px;
  }
`;

export default ModalContactDiv;
