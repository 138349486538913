import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useAnalytics from '../hooks/useAnalytics';

const SidebarBurger = ({ open, setOpen }) => {
  const [isScrollingUp, setIsScrollingUp] = useState(true);

  const { sendEvent } = useAnalytics();

  // let lastScrollPosition = 0;
  const lastScrollPosition = useRef(0);

  // Set up a scroll event listener to track the scroll direction
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      const threshold = 40;

      // When the user is at the top of the page, show the SidebarBurger
      if (currentScrollPosition === 0) {
        setIsScrollingUp(true);
        // When the user scrolls up past the threshold, show the SidebarBurger
      } else if (
        currentScrollPosition <
        lastScrollPosition.current - threshold
      ) {
        setIsScrollingUp(true);
        // When the user scrolls down past the threshold, hide the SidebarBurger
      } else if (
        currentScrollPosition >
        lastScrollPosition.current + threshold
      ) {
        setIsScrollingUp(false);
      }
      // Update the last scroll position
      lastScrollPosition.current = currentScrollPosition;
    };
    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle the click event for the SidebarBurger
  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);

    sendEvent({
      category: 'navigation',
      action: 'click',
      label: 'sidebar_burger',
    });
  };

  return (
    <BurgerWrapper isScrollingUp={isScrollingUp}>
      <StyledBurger
        className='sidebar-burger'
        open={open}
        onClick={handleClick}
        type='button'
      >
        <div />
        <div />
        <div />
      </StyledBurger>
    </BurgerWrapper>
  );
};

const BurgerWrapper = styled.div`
  position: fixed;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 100;
  display: block;
  opacity: ${({ isScrollingUp }) => (isScrollingUp ? 1 : 0)};
  transform: ${({ isScrollingUp }) =>
    isScrollingUp ? 'translateY(0)' : 'translateY(-20px)'};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  pointer-events: ${({ isScrollingUp }) => (isScrollingUp ? 'auto' : 'none')};
`;

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ theme }) => theme.colors.textColor};
    border-radius: 10px;
    transition: all 0.3s linear;
    /* position: relative; */
    transform-origin: 1px;

    &:first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export default SidebarBurger;
