import { createGlobalStyle } from 'styled-components';
import background from 'assets/backgrounds/mesh-gradient.png';

export const GlobalStyles = createGlobalStyle`

  html, body {
    overflow-y: ${({ open }) => (open ? 'hidden' : 'visible')};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    
  }
  
  //TODO: Put code below in a debug document
   /* *, *::after, *::before {
    box-sizing: border-box;
    outline: 1px solid red;
  }  */

  /* #root {
    white-space: pre-line;
    margin: 0 auto;
  } */

  
  body {
    background-color: ${({ theme }) => theme.background};
    /* background-image: url(${background});
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat; */
    color: ${({ theme }) => theme.textColor};
    transition: all .5s linear;
    font-family: 'PT Sans', sans-serif;    
  }


  p {
    font-size: clamp(1.2rem, 2vw, 1.4rem);
    line-height: 1.6;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.colors.textColor};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.accentColor1} ; 
  }

  h2 {
    font-size: clamp(3rem,11vw + 1rem,8rem);
    margin: 2rem 0;
    text-transform: uppercase;
  }

  h3 {
    font-size: clamp(2rem, 4vw, 4rem);
  }

  h4 {
    font-size: clamp(1.3rem, 2.5vw, 3rem);
  }

  h5 {
    margin: 1.2rem 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.primary};
  }

  /* input + label {
  display: inline-flex;
  margin-right: 20px;
  } */

  label {
    font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  }

  input[type=text],
  textarea,
  select {
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: none;
}

input, textarea {
  margin-top: 0.6rem;
 
}

.form-control {
  margin-bottom: 20px;
}

button {
  background-color: ${({ theme }) => theme.colors.accentColor1};
  color: ${({ theme }) => theme.colors.textColor};
  border-radius: 0.5rem;
  outline: none;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  padding: 1rem 2rem;
  cursor: pointer;
}

address {
  color: ${({ theme }) => theme.colors.accentColor2};
}

.btn {
  background-color: #025e73;
  color: #fff;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 14px;
  font-weight: 500;
  width: 100%;

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
    cursor: pointer;
    background: #011f26;
    box-shadow: none;
    color: #fef8ec;
  }
}

`;

// export const lightTheme = {
//   primaryWhite: '#FEF8EC',
//   primaryWhite2: '#FCE8C5',
//   primaryDark: '#011F26',
//   secondaryDark: '#A5A692',
//   secondaryDark2: '#BFB78F',
//   accentPrimary: '#025E73',
//   accentSecondary: '#F2A516',
// };

// export const $lightTheme = {
//   primaryWhite: '#f4f4f4',
//   primaryDark: '#011F26',
//   secondaryDark: '#A5A692',
//   secondaryDark2: '#BFB78F',
//   accentPrimary: '#025E73',
//   accentSecondary: '#F2A516',
// };

// export const darkTheme = {
//   body: 'f4f4f4',
//   backgroundColor: '#FFFFF3',
//   primaryColor: '#030301',
//   secondaryColor: '#B7AD99',
//   fontColor: '#333333',
//   secondaryFontColor: '#1b1b1b',
//   secondaryBackground: '#f4f4f4',
// };

// export const extraDarkTheme = {
//   body: '#1b1b1b',
//   primaryColor: '#FFFFF3',
//   secondaryColor: '#00D9C0',
//   fontColor: '#FFFFF3',
//   secondaryFontColor: '#00D9C0',
//   secondaryBackGround: 'rgb(42, 43, 46)',
// };

/*  

lightBg
lightTextDesc
dark
primary
darkText


*/
