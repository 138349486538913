import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Error = () => {
  return (
    <>
      <Helmet>
        <title>404 - Miklagård</title>
      </Helmet>
      <Section>
        <Heading>404</Heading>
        <Message>Fel sida, testa igen :)</Message>
        <HomeLink to='/'>Hem</HomeLink>
      </Section>
    </>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const Heading = styled.h1`
  font-size: 6rem;
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.5rem;
  color: #606060;
  margin-bottom: 1.5rem;
`;

const HomeLink = styled(Link)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid #0077cc;
  }
`;

export default Error;
