const theme = {
  colors: {
    primary: '#487A80', // to primary elements such as headers, navigation bars, and buttons.
    secondary: '#34495E', // to secondary elements such as paragraphs, labels, and other text. or for secondary elements, like subheaders and secondary buttons.
    accentColor1: '#7F8C8D', // to highlight specific elements like links, icons, or as a background for quotes or other important text.
    accentColor2: '#95A5A6', // to highlight specific elements like links, icons, or as a background for quotes or other important text.
    textColor: '#D7D7D7',
    background: '#1a1a1a',
  },

  borderRadius: '8px',
  letterSpacing: '1px',
  transition: `all 0.3s linear`,
  background: '#1a1a1a',

  animations: {
    transition: `all 0.3s linear`,
  },

  breakpoints: {
    md: '440px',
    td: '768px',
    lg: '1024px',
  },

  page: {
    width: '90vw',
    margin: `0 auto`,
    minHeight: '100vh',
  },
};

export default theme;
