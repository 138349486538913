import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import styled, { keyframes } from 'styled-components';

const FormComponent = ({ onSubmit, fields, validationSchema }) => {
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async data => {
    setLoading(true);
    await onSubmit(data, () => setLoading(false));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  return (
    <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
      {fields.map(field => {
        const { name, label, type, placeholder, validation } = field;
        const Component = type === 'textarea' ? TextArea : Input;
        return (
          <div key={name}>
            <Label htmlFor={name}>{label}</Label>
            <Component
              {...register(name, validation)}
              name={name}
              type={type !== 'textarea' ? type || 'text' : 'undefined'}
              placeholder={placeholder}
              id={name}
            />
            {errors[name] && (
              <ErrorMessage>{errors[name].message}</ErrorMessage>
            )}
          </div>
        );
      })}
      <SubmitButton type='submit' disabled={loading}>
        {loading ? <Loader /> : 'Submit'}
      </SubmitButton>
    </FormWrapper>
  );
};

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

const SubmitButton = styled.button`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 3rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textColor};
  cursor: pointer;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-top: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
  margin: 20px auto -20px auto;
`;

const Input = styled.input`
  height: 3rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0 1rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.textColor};
`;

const TextArea = styled.textarea`
  height: 10rem;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 1rem;
  padding: 0 0.5rem;
`;

export default FormComponent;
