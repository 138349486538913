import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa';
import { StickyContainer } from 'react-sticky';

// MENU COMPONENT

export const MenuContainer = styled.main`
  max-width: 900px;
  margin: 0 auto;
  position: relative;
`;

export const MenuWrapper = styled.section`
  width: 90%;
  max-width: 780px;
  margin: 5rem auto;
`;

export const MenuHeader = styled.header`
  position: relative;

  /* transform: translate(-50%, -50%);
  top: 50%;  */
`;

export const Headline = styled.h3`
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
`;

export const BackLink = styled(Link)`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
`;

export const ArrowBack = styled(FaAngleLeft)`
  font-size: 2rem;
  color: #030301;
`;

export const CategoryCard = styled.div`
  /* backdrop-filter: blur(20px) brightness(50%);
  -webkit-backdrop-filter: blur(20px) brightness(50%); */
  background-color: ${({ theme }) => theme.colors.background};
  padding-top: 3rem;
`;

export const StyledStickyContainer = styled(StickyContainer)`
  min-height: 100px;
`;

export const MenuItem = styled.article`
  width: 100%;
  margin-bottom: 2.5rem;
`;

export const SubCategory = styled.h3`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.7rem;
  margin-bottom: 1rem;
`;

export const ItemWrapper = styled.div`
  /* display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem; */
`;

export const Item = styled.div`
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  /* min-height: 130px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const CategoryDesc = styled.p`
  &:first-letter {
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export const Name = styled.h4`
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;

  &:first-letter {
    text-transform: uppercase;
    font-size: 1.7em;
    font-weight: 500;
  }
`;

export const Price = styled.h4`
  text-transform: capitalize;
  margin: 0;
  color: ${({ theme }) => theme.colors.accentColor2};
`;

export const Description = styled.p`
  &:first-letter {
    text-transform: capitalize;
  }

  width: clamp(30ch, 50%, 55ch);
`;
