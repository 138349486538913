import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

import ReactGA from 'react-ga4';
import Modal from 'components/Modal';
import PrivacyPolicy from 'pages/PrivacyPolicy';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldReverse, setShouldReverse] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const userDecision = localStorage.getItem('accepted');
    if (!userDecision) {
      setIsVisible(true);
    }
  }, []);

  const handleButtonClick = userDecision => {
    setShouldReverse(true);
    setTimeout(() => {
      setIsVisible(false);
      if (userDecision === 'accepted') {
        ReactGA.initialize(process.env.REACT_APP_GA4);
      }
      localStorage.setItem('accepted', userDecision);
    }, 1000);
  };

  const handleAccept = () => handleButtonClick('accepted');
  const handleReject = () => handleButtonClick('rejected');

  // Modal
  const handleModalOpen = e => {
    setIsModalOpen(!isModalOpen);
    e.preventDefault();
  };

  const handleModalClose = () => setIsModalOpen(false);

  return (
    isVisible && (
      <Banner isVisible={isVisible} shouldReverse={shouldReverse}>
        <p>
          Denna webbplats använder en cookie för att säkerställa att du får den
          bästa upplevelsen på vår webbplats och för att samla in användardata
          via Google Analytics.
        </p>
        <p>
          Du kan välja att acceptera cookien eller fortsätta använda webbplatsen
          utan spårning.
        </p>
        <div>
          <button onClick={handleAccept}>Acceptera</button>
          <button onClick={handleReject}>Acceptera inte</button>
        </div>
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <PrivacyPolicy />
        </Modal>
        <a onClick={handleModalOpen}>Integritetspolicy</a>
      </Banner>
    )
  );
};

const fadeInUpwards = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutDownwards = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
`;

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  background: #576854;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  height: 350px;
  opacity: 0;
  display: grid;
  place-items: center;
  ${({ isVisible, shouldReverse }) =>
    isVisible && !shouldReverse
      ? css`
          animation: ${fadeInUpwards} 1s forwards;
          animation-delay: 2.5s;
        `
      : shouldReverse
      ? css`
          animation: ${fadeOutDownwards} 1s forwards;
        `
      : ''}
  p {
    margin: 0;
    font-size: 0.9rem;
    width: clamp(300px, 10ch, 600px);
    line-height: normal;
    padding: 0;
  }

  div {
    margin: 0.5rem 0;
  }

  button {
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.accentColor2};
    text-decoration: underline;
  }
`;

export default CookieBanner;
