// Loader.js
import React from 'react';
import { BallTriangle } from 'react-loader-spinner';
import styled from 'styled-components';

const Loader = () => {
  return (
    <SpinningContainer>
      <BallTriangle
        height={100}
        width={100}
        radius={5}
        color='#333'
        ariaLabel='ball-triangle-loading'
        wrapperClass={{}}
        wrapperStyle=''
        visible={true}
      />
    </SpinningContainer>
  );
};

const SpinningContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f4f4f4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

export default Loader;
