import styled from 'styled-components';
import * as FiIcons from 'react-icons/fi';
import * as BiIcons from 'react-icons/bi';

const CateringHero = ({ handleClickScroll }) => {
  return (
    <Article>
      <h3>Låt din nästa fest bli en fest att minnas med Miklagårds catering</h3>
      {/* <p>
        Vi erbjuder catering för alla tillfällen, oavsett om det är en liten
        middag för två eller en stor fest för många gäster. Vårt team av duktiga
        kockar och servitörer ser till att allt går smidigt så att du kan
        fokusera på att njuta av tillställningen tillsammans med dina gäster.
      </p> */}
      <p>
        Vi har tre olika cateringpaket att välja mellan: Small, Medium samt
        Large. Varje paket innehåller olika mängd rätter, så det finns något för
        alla behov. Vi har även möjlighet att anpassa menyn efter dina specifika
        önskemål och allergier.
      </p>
      <br />
      {/* <p>
        För att göra en förfrågan om catering,
        <strong style={{ cursor: 'pointer' }} onClick={handleClickScroll}>
          &nbsp;vänligen fyll i formuläret nedan
        </strong>
        . Skriv gärna in så mycket information som möjligt om ditt tillfälle, så
        att vi kan ge dig den bästa möjliga servicen. Du är också välkommen att
        kontakta oss via e-post eller telefon om du har frågor eller
        funderingar.
      </p> */}

      <ul>
        <li className='first-two'>
          <FiIcons.FiPhoneOutgoing /> <a href='tel:018-508800'>018-501800</a>
        </li>
        <li className='first-two'>
          <BiIcons.BiMailSend />
          <a href='mailto:info@miklagarduppsala.se'>info@miklagarduppsala.se</a>
        </li>
      </ul>
      <p>
        Vi ser fram emot att höra från dig och att skapa en minnesvärd middag
        tillsammans med dig!
      </p>
    </Article>
  );
};

const Article = styled.article`
  max-width: 40rem;
  margin: 0 auto;

  article:first-of-type > p {
    margin: 0 auto;
  }

  li {
    cursor: default;
  }

  ul {
    justify-self: center;
    margin-left: 2rem;
    list-style-type: none;
    padding-left: 0;
    line-height: 2rem;
  }

  ul > li {
    display: flex;
    align-items: center;
  }

  ul > li > a {
    padding-left: 0.3rem;
    font-weight: 600;
  }

  .parallax-banner {
    background-size: cover;
    background-position: top;
    position: relative;

    height: 100%;
  }
  img {
    width: 100%;
  }

  .img-container {
    position: relative;
  }

  .h2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
  }

  .dark-background {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

export default CateringHero;
