import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        {/* <Logo>Miklagård</Logo> */}
        <FooterContactWrapper>
          <FooterContactItems>
            <SocialMedia>
              <SocialMediaWrap>
                <Headline>följ oss</Headline>
                <SocialIcons>
                  <SocialIconLink
                    href='//www.facebook.com/miklagarduppsala'
                    target='_blank'
                    aria-label='Facebook'
                  >
                    <FaIcons.FaFacebook />
                  </SocialIconLink>
                  <SocialIconLink
                    href='//www.instagram.com/miklagarduppsala'
                    target='_blank'
                    aria-label='Instagram'
                  >
                    <FaIcons.FaInstagram />
                  </SocialIconLink>
                </SocialIcons>
              </SocialMediaWrap>
            </SocialMedia>
            <Adress1>
              <Headline>hitta oss</Headline>
              Årstagatan 6 <br />
              754 34 Uppsala
            </Adress1>
            <Adress2>
              <Headline>kontakta oss</Headline>
              <ContactItem href='tel:018-508800'>
                018-508800
                <br />
              </ContactItem>
              <ContactItem href='tel:018-250060'>
                018-250060 <br />
              </ContactItem>
              <ContactItem href='mailto:info@miklagarduppsala.se'>
                info@miklagarduppsala.se
              </ContactItem>
            </Adress2>
          </FooterContactItems>
          {/* <FooterLinksWrapper>
            <FooterLinksItems>
              <FooterLink to='/meny'>Grill & Meze</FooterLink>
              <FooterLink to='/takeaway'>Pizza & Kebab</FooterLink>
              <FooterLink to='/omoss'>Om Miklagård</FooterLink>
              <FooterLink to='/hitta'>Hitta oss</FooterLink>
            </FooterLinksItems>
          </FooterLinksWrapper> */}
        </FooterContactWrapper>
      </FooterWrapper>
      <WebsiteRightWrapper>
        <WebsiteRights>
          © Miklagård, Uppsala {new Date().getFullYear()}
        </WebsiteRights>
      </WebsiteRightWrapper>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const FooterWrapper = styled.div`
  padding: 3rem;
  max-width: 800px;
  margin: 0 auto;

  /* @media screen and (min-width: 768px) {
    flex-direction: row;
  } */
`;

/* FIXME: create a global font-style: normal */

const Adress1 = styled.address`
  font-style: normal;
  align-self: start;
  margin: 0.3rem;
`;

const Adress2 = styled.address`
  font-style: normal;
  margin: 0.3rem;
`;

const Headline = styled.h5`
  text-transform: uppercase;
`;

const ContactItem = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.accentColor2};
`;

const FooterContactWrapper = styled.div`
  width: 100%;
  line-height: 1.5rem;
`;

const FooterContactItems = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
`;

const FooterLinksWrapper = styled.div``;

const FooterLinksItems = styled.ul``;

const FooterLink = styled(Link)``;

const SocialMedia = styled.div`
  margin: 0.3rem;
  align-self: start;
`;

const SocialMediaWrap = styled.div`
  max-width: 3.4rem;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 584px) {
    flex-direction: column;
    align-items: flex-start;
  }
  /* width: 240px; */
`;

const SocialIconLink = styled.a`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.accentColor2};
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
  }
`;

const Logo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const WebsiteRightWrapper = styled.div`
  display: flex;
`;

const WebsiteRights = styled.small`
  color: ${({ theme }) => theme.colors.accentColor1};
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

export default Footer;
