import styled from 'styled-components';

const CateringServices = () => {
  return (
    <ServicesContainer>
      <ServicesH1>Våra färdiga paket</ServicesH1>
      <ServiceDescription>Pris per kuvert samt inkl. moms</ServiceDescription>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesTextWrapper>
            <ServicesH2>small</ServicesH2>
            <Underline></Underline>
            <ServicesP>Smått & gott</ServicesP>
            <List>
              <Item>bröd</Item>
              <Item>tzatziki</Item>
              <Item>hummus</Item>
              <Item>muhammara</Item>
              <Item>ostrullar</Item>
              <Item>adanspett</Item>
              <Item>kycklingspett</Item>
              <Item>
                Välj mellan: ris,
                <br /> bulgur, pommes frites & klyftpotatis
              </Item>
            </List>
          </ServicesTextWrapper>
          <Price>259kr</Price>
        </ServicesCard>

        <ServicesCard>
          <ServicesTextWrapper>
            <ServicesH2>medium</ServicesH2>
            <Underline></Underline>
            <ServicesP>Så där lagom</ServicesP>
            <List>
              <Item>bröd</Item>
              <Item>tzatziki</Item>
              <Item>hummus</Item>
              <Item>saksuka</Item>
              <Item>icli köfte</Item>
              <Item>falafel</Item>
              <Item>adanaspett</Item>
              <Item>kycklingspett</Item>
              <Item>lammspett</Item>
              <Item>
                Välj mellan: ris, bulgur, pommes frites & klyftpotatis
              </Item>
            </List>
          </ServicesTextWrapper>
          <Price>359kr</Price>
        </ServicesCard>

        <ServicesCard>
          <ServicesTextWrapper>
            <ServicesH2>large</ServicesH2>
            <Underline></Underline>
            <ServicesP>För den stora festen</ServicesP>
            <List>
              <Item>bröd</Item>
              <Item>tzatziki</Item>
              <Item>hummus</Item>
              <Item>saksuka</Item>
              <Item>ostrullar</Item>
              <Item>icli köfte</Item>
              <Item>falafel</Item>
              <Item>adanaspett</Item>
              <Item>kycklingspett</Item>
              <Item>lammspett</Item>
              <Item>coban sallad</Item>
              <Item>kycklingvingar</Item>
              <Item>panna cotta</Item>
              <Item>
                Välj mellan: ris, bulgur, pommes frites & klyftpotatis
              </Item>
            </List>
          </ServicesTextWrapper>
          <Price>499kr</Price>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export const ServicesContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  width: 100%;
`;

export const ServicesWrapper = styled.div`
  /* max-width: 1000px;
  margin: 0 auto; */
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 1rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    /* padding: 0 20px; */
  }
`;

export const ServicesCard = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 3rem;
  box-shadow: 0px 1px 90px -78px rgba(51, 46, 46, 0.44);
  -webkit-box-shadow: 0px 1px 90px -78px rgba(51, 46, 46, 0.44);
  -moz-box-shadow: 0px 1px 90px -78px rgba(51, 46, 46, 0.44);
  transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  /* border: 1px solid #f2a94a;
  background-color: #f2a94a;
  background-image: linear-gradient(57deg, #f2a94a 0%, #f7ce68 100%); */
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    /* cursor: pointer; */
  }

  @media screen and (min-width: 1200px) {
    &:nth-child(3) {
      order: 2;
    }
    &:nth-child(2) {
      order: 3;
    }
  }
`;

const ServicesIcon = styled.img`
  height: 10rem;
  width: 10rem;
  margin-bottom: 0.6rem;
`;

const ServicesTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  /* margin-bottom: 4rem; */

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ServicesH2 = styled.h2`
  font-size: 2rem;
  margin-bottom: 0.6rem;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textColor};
`;

const ServiceDescription = styled.p`
  font-style: italic;
  width: clamp(250px, 50vw, 30ch);
  margin: 0 0 2rem 0;
  text-align: center;
`;

const ServicesP = styled.p`
  width: clamp(10ch, 22ch, 25ch);
  text-align: center;
  font-size: clamp(1.05rem, 1.5vw, 1.2rem);
  letter-spacing: 1px;
  font-style: italic;
`;

const List = styled.ul`
  padding: 0;
  width: 8rem;
`;

const Item = styled.li`
  color: ${({ theme }) => theme.colors.textColor};
  display: list-item;
  padding-inline-start: 1ch;
  &::first-letter {
    text-transform: uppercase;
    font-size: 1.1rem;
  }
  &::marker {
    color: #fff;
  }
`;

const Price = styled.p`
  font-size: clamp(1.4rem, 3vw, 1.8rem);
  font-weight: 700;
  padding-top: 0;
  color: #fff;
`;

const Underline = styled.div`
  width: 40px;
  border: 2px solid #fff; ;
`;

export default CateringServices;
