import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

// import CateringForm from './CateringForm/CateringForm';
import ParallaxHeader from 'components/ParallaxHeader';
import picture from 'assets/images/catering/dessert.jpg';
import { BackButton } from 'styles/ButtonElements';
import CateringServices from './CateringServices';
import { Helmet } from 'react-helmet';

import CateringForm from './CateringForm';
import CateringHero from './CateringHero';

const CateringPage = () => {
  const navigate = useNavigate();
  const handleClickScroll = () => {
    const element = document.getElementById('form');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta name='pageName' content='Catering Page' />
        <title>Catering</title>
      </Helmet>
      <MainContainer id='catering-page'>
        <ParallaxHeader />
        <MainWrapper>
          <CateringHero handleClickScroll={handleClickScroll} />
          <Underline />
          <CateringServices />
          {/* <Underline />
        <article id='form'>
          <h3>Gör en förfrågan om catering</h3>
          <p>
            Fyll i formuläret nedan så återkommer vi till dig så snart som
            möjligt med mer information och en offert. För bästa upplevelse
            behöver vi få in förfrågan tre dagar före önskad leveransdatum.
          </p>
          <p>
            Vi ser fram emot att skapa en minnesvärd middag tillsammans med dig!
          </p>
        </article> */}
          {/* <CateringForm /> */}
        </MainWrapper>
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div``;

const MainWrapper = styled.main`
  display: grid;
  max-width: 90vw;
  margin: 0 auto;
  /* article {
    max-width: 40rem;
    margin: 0 auto;
  }

  article:first-of-type > p {
    margin: 0 auto;
  }

  li {
    cursor: default;
  }

  ul {
    justify-self: center;
    margin-left: 2rem;
    list-style-type: none;
    padding-left: 0;
    line-height: 2rem;
  }

  ul > li {
    display: flex;
    align-items: center;
  }

  ul > li > a {
    padding-left: 0.3rem;
    font-weight: 600;
  }

  .parallax-banner {
    background-size: cover;
    background-position: top;
    position: relative;

    height: 100%;
  }
  img {
    width: 100%;
  }

  .img-container {
    position: relative;
  }

  .h2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
  }

  .dark-background {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  } */
`;

// const ParallaxContainer = styled.div`
//   height: 20rem;
// `;

// const PageHeader = styled.div`
//   /* background-size: cover;
//   background-position: top; */
//   /* position: relative; */
//   /* height: 40vh; */
//   display: grid;

//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-image: url(${picture});
//     filter: blur(2px) brightness(68%);
//     -webkit-filter: blur(2px) brightness(68%);
//     -moz-filter: blur(2px) brightness(68%);
//     background-size: cover;
//     background-position: top;
//   }
// `;

// /* // export const BackLink = styled(Link)`;
// //   position: absolute;
// //   top: 50%;
// //   transform: translate(-50%, -50%);
// //   display: grid;
// //   place-items: center;
// // `;

// // export const ArrowBackIcon = styled(FaAngleLeft)`
// //   font-size: 2rem;
// //   color: #030301;
// // `; */

// const ArrowBackIcon = styled(IoIosArrowBack)`
//   font-size: 30px;
// `;

// const Header = styled.header`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   align-self: center;
//   /* transform: translate(-50%, -50%);
//   top: 50%;  */
// `;

// const BackButtonWrapper = styled.div`
//   grid-area: 1/1;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   color: #f4f4f4;
//   position: absolute;
//   top: 1rem;
//   left: 1rem;
// `;

// const Headline = styled.h2`
//   text-align: center;
//   text-transform: uppercase;
//   font-weight: 500;
//   grid-column: 2;
//   color: #f4f4f4;
//   z-index: 2;
// `;

// const Description = styled.p`
//   text-align: left;
// `;

const Underline = styled.div`
  width: 10rem;
  height: 0.25rem;
  background: lightgray;
  margin: 3rem auto;
`;

export default CateringPage;
