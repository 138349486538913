import { Link } from 'react-router-dom';
import styled from 'styled-components';

import img from 'assets/images/catering/catering.jpg';
import { Button } from 'styles/ButtonElements';

const Catering = () => {
  return (
    <CateringContainer>
      <ColumnRow>
        <Img src={img} alt='plates on a table' />

        <Column2>
          <TextWrapper>
            <Heading>
              Miklagårds meny - den perfekta cateringen för din privata fest
              eller företagsevent
            </Heading>
            <Subtitle>
              Miklagårds meny är den perfekta cateringen för din privata fest
              eller ditt företagsevenemang! Meze i alla dess former och smaker,
              liksom resten av menyn, är skapad för en tillställning.
            </Subtitle>
            <BtnWrap>
              <Link to='/catering'>
                <button>mer om catering</button>
              </Link>
            </BtnWrap>
          </TextWrapper>
        </Column2>
      </ColumnRow>
    </CateringContainer>
  );
};

const CateringContainer = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const ColumnRow = styled.div`
  display: grid;
  grid-gap: 2rem 4rem;
  align-items: center;
  justify-items: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Column1 = styled.div``;

const Column2 = styled.div``;

const Img = styled.img`
  max-width: 25rem;
  border-radius: 0.5rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    order: 1;
  }
`;

const TextWrapper = styled.article``;

const Heading = styled.h3``;

const Subtitle = styled.p`
  padding-bottom: 2rem;
`;

const BtnWrap = styled.div``;

export default Catering;
