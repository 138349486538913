import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, children }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'scroll';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleClose = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      onClose();
    }, 300);
  };

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return ReactDOM.createPortal(
    <ModalWrapper className='ModalWrapper' onClick={handleOverlayClick}>
      <ModalContent isAnimating={isAnimating}>
        <CloseButton>
          <a onClick={handleClose}>&times;</a>
        </CloseButton>
        {React.cloneElement(children, { handleClose })}
      </ModalContent>
    </ModalWrapper>,
    document.body
  );
};

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
`;

const animateTop = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
`;

const animateClose = keyframes`
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: ${({ isAnimating }) =>
    isAnimating ? animateClose : animateTop};
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  max-width: 600px;
  border-radius: 0.5rem;
  width: 90vw;
  margin: 0 auto;

  @media (min-width: 375px) {
    width: 80vw;
  }
`;

const CloseButton = styled.span`
  color: ${({ theme }) => theme.colors.accentColor1};
  font-size: 40px;
  display: flex;
  justify-content: flex-end;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default Modal;
