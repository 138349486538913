import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <StyledPolicy>
      <h2>Integritetspolicy</h2>
      <p>
        På vår webbplats värnar vi om din integritet och är måna om att skydda
        dina personuppgifter. Denna integritetspolicy förklarar hur vi samlar in
        och använder din information.
      </p>
      <h3>Insamling av information</h3>
      <p>
        Vi samlar inte in några personuppgifter om dig när du besöker vår
        webbplats, såvida du inte väljer att bli spårad av Google Analytics.
      </p>
      <h3>Google Analytics</h3>
      <p>
        Vi använder Google Analytics för att samla in anonymiserad information
        om hur besökare interagerar med vår webbplats. Detta hjälper oss att
        förbättra och optimera vår webbplats för att erbjuda en bättre
        användarupplevelse. Google Analytics använder cookies för att samla in
        denna information. Om du inte vill att din data ska samlas in av Google
        Analytics kan du välja att inte bli spårad när du besöker vår webbplats.
      </p>
      <h3>Dina val</h3>
      <p>
        När du besöker vår webbplats kommer ett meddelande att visas där du kan
        välja om du vill bli spårad av Google Analytics eller inte. Du kan när
        som helst ändra ditt val genom att rensa dina cookies i din webbläsare
        och sedan besöka webbplatsen igen för att göra ett nytt val.
      </p>
      <h3>Ändringar i denna integritetspolicy</h3>
      <p>
        Vi kan komma att uppdatera denna integritetspolicy från tid till annan.
        Om vi gör några betydande ändringar i hur vi hanterar dina
        personuppgifter kommer vi att informera dig genom att uppdatera datumet
        för den senaste ändringen längst ner på denna sida.
      </p>
      <p>Senast uppdaterad: 2023-04-24</p>
    </StyledPolicy>
  );
};

const StyledPolicy = styled.div`
  /* max-width: 600px; */
  /* margin: 0 auto; */
  /* padding: 2.5rem 1.5rem; */
  /* height: 100%; */

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export default PrivacyPolicy;
