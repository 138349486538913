import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Button = styled(Link)`
  background-color: #025e73;
  color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius};
  outline: none;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
    cursor: pointer;
    background: #011f26;
    box-shadow: none;
    color: #fef8ec;
  }
`;

export const SubmitButton = styled.button`
  background-color: #025e73;
  color: #fff;
  border-radius: 8px;
  outline: none;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.74);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
    cursor: pointer;
    background: #011f26;
    box-shadow: none;
    color: #fef8ec;
  }
`;

export const BackButton = styled.button`
  /* position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 1rem; */
  background: transparent;
  border: none;
  text-transform: uppercase;
  padding-left: 0;
  color: #f4f4f4;
`;
