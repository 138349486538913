import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const useInitializeAnalytics = () => {
  useEffect(() => {
    const userDecision = localStorage.getItem('accepted');
    if (userDecision === 'accepted') {
      ReactGA.initialize(process.env.REACT_APP_GA4);
    }
  }, []);
};

export default useInitializeAnalytics;
