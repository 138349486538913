import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from 'react-scroll-parallax';

import pic from 'assets/images/catering/dessert.jpg';
import PlaceholderImage from 'assets/images/catering/lazy_dessert.jpg';

const ParallaxHeader = () => {
  return (
    <ParallaxContainer>
      <ParallaxProvider>
        <ParallaxBanner className='parallax-banner' k>
          <ParallaxBannerLayer speed={18}>
            <LazyLoadImage
              loading='lazy'
              className='bg-img'
              src={pic}
              alt='small plates on table'
              PlaceholderSrc={PlaceholderImage}
              effect='blur'
              width={100}
            />

            <div loading='lazy' className='overlay' />
          </ParallaxBannerLayer>

          <ParallaxBannerLayer
            opacity={[1, 0.7]}
            className='parallax-banner-text'
            speed={50}
          >
            <h2>catering</h2>
          </ParallaxBannerLayer>
        </ParallaxBanner>
      </ParallaxProvider>
    </ParallaxContainer>
  );
};

const ParallaxContainer = styled.header`
  height: 40vh;
  width: 100%;

  .bg-img {
    position: absolute;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  h2 {
    color: ${({ theme }) => theme.primaryLight};
    max-width: 12ch;
    font-weight: 700;
    padding-bottom: 23rem;

    @media (min-width: 768px) {
      padding-bottom: 24rem;
    }

    @media (min-width: 1200px) {
      padding-bottom: 30rem;
    }

    span {
      white-space: nowrap;
    }
  }

  .parallax-banner {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .parallax-banner-text {
    display: grid;
    align-items: end;
    justify-items: center;

    h2 {
      /* position: absolute;
      top: 25rem; */
      color: white;
    }
  }

  .overlay {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

export default ParallaxHeader;
