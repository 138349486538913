const data = [
  {
    category: 'meze',
    subCategory: 'kall meze',
    description: '',
    categoryId: 100,
    items: [
      {
        id: 7,
        name: 'hummus',
        desc: 'kikärtspuré, tahini, vitlök, citron, olivolja och kummin',
        price: 69,
      },
      {
        id: 8,
        name: 'muhammara',
        desc: 'kryddig paprikaröra, valnötter, vitlök och olivolja',
        price: 69,
      },
      {
        id: 9,
        name: 'saksuka',
        desc: 'röra av aubergine, zucchini, paprika, krossade tomat, vitlök och olivolja',
        price: 69,
      },
      {
        id: 10,
        name: 'tzatziki',
        desc: 'finhackad gurka, vitlök, yoghurt som toppas med olivolja och mynta',
        price: 69,
      },
      {
        id: 11,
        name: 'vinbladsdolma',
        desc: 'hemlagad vinbladsdolma med ris',
        price: 59,
      },
      {
        id: 12,
        name: 'coban sallad',
        desc: 'finhackad tomat, gurka, rödlök, persilja och olivolja',
        price: 79,
      },
      {
        id: 13,
        name: 'baba ganoush',
        desc: 'grillad aubergineröra med paprika, vitlök, tomat, lök och olivolja',
        price: 59,
      },
      {
        id: 14,
        name: 'fetaoströra',
        desc: 'Fetaost, persilja, paprikapulver och grädde',
        price: 69,
      },
      {
        id: 15,
        name: 'kisir',
        desc: 'Bulgur, gurka, tomat, persilja och granatäppelsirap',
        price: 69,
      },
      {
        id: 16,
        name: 'marinerade oliver',
        desc: '',
        price: 69,
      },
      {
        id: 17,
        name: 'kisir',
        desc: 'bulgur, gurka, tomat, persilja och granatäppelsirap',
        price: 69,
      },
      {
        id: 188,
        name: 'avsmakningsmeny (för 2 personer)',
        desc: 'Hummus. muhammara. vinbladsdolma. falafel, tzatziki, ostrullar. frästa championioner, kvcklinaspett, fetaoströra, adanaspett (eller kockens val)',
        price: 259,
      },
    ],
  },
  {
    category: 'meze',
    subCategory: 'varm meze',
    description: '',
    categoryId: 101,
    items: [
      {
        id: 66,
        name: 'vitlöksbröd',
        desc: 'vitlöksbröd med tzatziki',
        price: 69,
      },
      {
        id: 6,
        name: 'ugnsgrillad fetaost',
        desc: 'toppad med olivolja, organo och turkisk pulbiber',
        price: 89,
      },
      {
        id: 1,
        name: 'icli köfte',
        desc: 'fyllda bulgurbollar med köttfärs, paprikapure, lök och olivolja',
        price: 69,
      },
      {
        id: 2,
        name: 'turkiska ostrullar',
        desc: 'friterade filodegsrullar med fårost och persilja',
        price: 59,
      },
      {
        id: 233,
        name: 'falafel',
        desc: 'Hemlagad falafel som serveras med hummus',
        price: 59,
      },
      {
        id: 233435,
        name: 'TURKISK KORV (SUCUK)',
        desc: 'Kryddig korv',
        price: 99,
      },
      {
        id: 22354234,
        name: 'GRÖNA MUSSLOR',
        desc: 'Gratinerade musslor med chili, vitlök och persilja',
        price: 109,
      },
      {
        id: 3234523,
        name: 'CHAMPINJON KAVURMA',
        desc: 'Frästa champinjoner, vitlök och persilja',
        price: 69,
      },
      {
        id: 323535,
        name: 'SCAMPI',
        desc: 'Scampi, fräst med chili, vitlök och persilja',
        price: 119,
      },
      {
        id: 32323,
        name: 'KYCKLINGSPETT',
        desc: 'Grillat kycklingspett med madeirasås',
        price: 99,
      },
      {
        id: 398989,
        name: 'adanaSPETT',
        desc: 'Grillat adanaspett med madeirasås',
        price: 99,
      },
      {
        id: 4534,
        name: 'lammSPETT',
        desc: 'Grillat lammspett med madeirasås',
        price: 109,
      },
      {
        id: 26362346,
        name: 'halloumi',
        desc: 'grillad halloumi som är toppad med olivolja',
        price: 99,
      },
    ],
  },
  {
    category: 'varmrätter',
    subCategory: 'fiskrätter',
    description: '',
    categoryId: 103,
    items: [
      {
        id: 31,
        name: 'röding',
        desc: 'Grillad röding, hummersås, sparris, citron, tomatsallad, oliver och fetaost. Serveras med kokt potatis',
        price: 289,
      },
      {
        id: 32,
        name: 'gös',
        desc: 'Grillad gös, vitvinsås, citron, sparris, tomatsallad, oliver och fetaost. Serveras med kokt potatis',
        price: 299,
      },
    ],
  },
  {
    category: 'varmrätter',
    subCategory: 'kötträtter',
    description: 'välj mellan: klyftpotatis, pommes frites, ris eller bulgur.',
    categoryId: 104,
    items: [
      {
        id: 18,
        name: 'kycklingspett',
        desc: 'kycklingspett, saksuka och tzatziki',
        price: 199,
      },
      {
        id: 19,
        name: 'lammspett',
        desc: 'lammspett, saksuka och tzatziki',
        price: 219,
      },
      {
        id: 20,
        name: 'adanaspett',
        desc: '90% lammfärs- 10% nötfärsspett med orientaliska kryddor, saksuka & tzatziki',
        price: 199,
      },
      {
        id: 22,
        name: 'beyti',
        desc: '90% lammfärs- 10% nötfärsspett i tunnbröd toppat med tomatsås, vitlöksyoghurt & brynt smör',
        price: 219,
      },
      {
        id: 24,
        name: 'lammracks',
        desc: 'lammracks, saksuka, tzatziki, och vitlökssky',
        price: 349,
      },
      {
        id: 252345235,
        name: 'oxfilémedaljonger',
        desc: 'Grillad oxfile. madeirasås. bearnaisesas, tomatsallad, oliver och fetaost',
        price: 299,
      },
      {
        id: 25,
        name: 'ryggbiff',
        desc: 'Ryggbiff, madeirasås, bearnaisesås, tomatsallad, oliver och fetaost',
        price: 259,
      },
      {
        id: 26,
        name: 'grillmix (minst 2pers)',
        desc: 'kycklingspett, adanaspett, lammspett, lammracks, tomatsallad, oliver, tzatziki och saksuka',
        price: 349,
      },
    ],
  },
  {
    category: 'varmrätter',
    subCategory: 'grytor',
    description: 'alla grytor är tomatbaserade och serveras med ris',
    categoryId: 105,
    items: [
      {
        id: 27,
        name: 'kycklinggryta',
        desc: 'kyckling, paprika, lök, vitlök och tomat',
        price: 289,
      },
      {
        id: 28,
        name: 'lammgryta',
        desc: 'lamm, paprika, lök, vitlök  och tomat',
        price: 219,
      },
      {
        id: 29,
        name: 'grönsaksgryta (vegan)',
        desc: 'sojabönor, zucchini, aubergine, paprika, lök, vitlök och tomat',
        price: 219,
      },
    ],
  },
  {
    category: 'varmrätter',
    subCategory: 'pastarätter',
    description: '',
    categoryId: 106,
    items: [
      {
        id: 2723532,
        name: 'kycklingpasta',
        desc: 'kyckling, hackad spenat, philadelphiaost, soltorkade tomater, lök, curry, grädde, parmesan',
        price: 189,
      },
      {
        id: 2823532,
        name: 'oxfilepasta',
        desc: 'oxfile, dijonsenap, lök, grädde, parmesan',
        price: 199,
      },
    ],
  },
  {
    category: 'varmrätter',
    subCategory: 'caesarsallad',
    description: 'romansallad, caesardressing, krutonger',
    categoryId: 108,
    items: [
      {
        id: 27567858,
        name: 'kyckling',
        desc: '',
        price: 178,
      },
      {
        id: 27325,
        name: 'halloumi',
        desc: '',
        price: 189,
      },
      {
        id: 27124335235,
        name: 'kyckling & halloumi',
        desc: '',
        price: 219,
      },
    ],
  },
  {
    category: 'pizza',
    subCategory: 'pizza klass 1',
    description: '',
    takeawayDesc: 'vi bakar traditionell surdegspizza!',
    takeawayDesc2: 'vegansk ost +20:-',
    takeawayDesc3: 'glutenfri pizzabotten +20:-',
    categoryId: 10645454,
    items: [
      {
        id: 38,
        name: 'margarita',
        desc: 'ost, tomatsås',
        price: 115,
        takeawayPrice: 90,
      },
      {
        id: 39,
        name: 'fungi',
        desc: 'champinjoner',
        price: 115,
        takeawayPrice: 90,
      },
      {
        id: 40,
        name: 'vesuvio',
        desc: 'skinka',
        price: 115,
        takeawayPrice: 90,
      },
    ],
  },
  {
    category: 'pizza',
    subCategory: 'pizza klass 2',
    description: '',
    takeawayDesc: 'vi bakar traditionell surdegspizza!',
    takeawayDesc2: 'vegansk ost +20:-',
    takeawayDesc3: 'glutenfri pizzabotten +20:-',
    categoryId: 107,
    items: [
      {
        id: 41,
        name: 'calzone',
        desc: 'skinka, (inbakad)',
        price: 120,
        takeawayPrice: 95,
      },
      {
        id: 42,
        name: 'capricciosa',
        desc: 'skinka, champinjoner',
        price: 120,
        takeawayPrice: 95,
      },
      {
        id: 43,
        name: 'hawaii',
        desc: 'skinka, ananas',
        price: 120,
        takeawayPrice: 95,
      },
      {
        id: 44,
        name: 'orientale',
        desc: 'köttfärs, lök',
        price: 120,
        takeawayPrice: 95,
      },
      {
        id: 45,
        name: 'orientale special',
        desc: 'köttfärs, ägg, lök',
        price: 120,
        takeawayPrice: 95,
      },
      {
        id: 46,
        name: 'altono',
        desc: 'tonfisk, lök',
        price: 120,
        takeawayPrice: 95,
      },
      {
        id: 47,
        name: 'opera',
        desc: 'tonfisk, skinka',
        price: 120,
        takeawayPrice: 95,
      },
      {
        id: 48,
        name: 'campagnola',
        desc: 'salami, lök, oliver',
        price: 120,
        takeawayPrice: 95,
      },
    ],
  },
  {
    category: 'pizza',
    subCategory: 'pizza klass 3',
    description: '',
    takeawayDesc: 'vi bakar traditionell surdegspizza!',
    takeawayDesc2: 'vegansk ost +20:-',
    takeawayDesc3: 'glutenfri pizzabotten +20:-',
    categoryId: 10834634634,
    items: [
      {
        id: 49,
        name: 'calzone special',
        desc: 'skinka, räkor (inbakad)',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 50,
        name: 'capri',
        desc: 'skinka, räkor, champinjoner',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 51,
        name: 'quattro stagioni',
        desc: 'skinka, räkor, champinjoner, kronärtskocka, oliver',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 52,
        name: 'du chef',
        desc: 'köttfärs, champinjoner, lök, tabasco',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 53,
        name: 'tre dogi',
        desc: 'räkor, tonfisk, musslor',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 54,
        name: 'vegetarisk',
        desc: 'champinjoner, lök, paprika, tomat, kronärtskocka, oliver',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 55,
        name: 'bussola',
        desc: 'skinka, räkor',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 56,
        name: 'parma',
        desc: 'skinka, köttfärs, lök, pepperoni, vitlökssås',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 57,
        name: 'hawaii special',
        desc: 'skinka, banan, curry',
        price: 125,
        takeawayPrice: 100,
      },
      {
        id: 58,
        name: 'azteka',
        desc: 'jalapeno, skinka, stark sås',
        price: 125,
        takeawayPrice: 100,
      },
    ],
  },
  {
    category: 'pizza',
    subCategory: 'pizza klass 4',
    description: '',
    takeawayDesc: 'vi bakar traditionell surdegspizza!',
    takeawayDesc2: 'vegansk ost +20:-',
    takeawayDesc3: 'glutenfri pizzabotten +20:-',
    categoryId: 109,
    items: [
      {
        id: 59,
        name: 'kycklingpizza',
        desc: 'kyckling, jalapeño, lök, champinjoner, paprika, bearnaisesås',
        price: 130,
        takeawayPrice: 100,
      },
      {
        id: 60,
        name: 'chanti',
        desc: 'kyckling, banan, ananas, curry, bearnaisesås',
        price: 130,
        takeawayPrice: 100,
      },
      {
        id: 61,
        name: 'kebabpizza',
        desc: 'kebabkött, lök, tomat, feferoni, valfri sås',
        price: 130,
        takeawayPrice: 100,
      },
    ],
  },
  {
    category: 'pizza',
    subCategory: 'pizza klass 5',
    description: '',
    takeawayDesc: 'vi bakar traditionell surdegspizza!',
    takeawayDesc2: 'vegansk ost +20:-',
    takeawayDesc3: 'glutenfri pizzabotten +20:-',
    categoryId: 110,
    items: [
      {
        id: 62,
        name: 'vikingkebab (inbakad)',
        desc: 'kebabkött, lök, tomat, isbergssallad, feferoni, valfri sås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 63,
        name: 'thorszelius',
        desc: 'fläskfilé, köttfärs, lök, jalapeño, salami, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 64,
        name: 'jimmys special',
        desc: 'fläskfilé, skinka, paprika, lök, pepperonikorv, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 65,
        name: 'fyra ostar',
        desc: 'fetaost, mozzarella, gorgonzola, pizzaost',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 66,
        name: 'grekisk pizza',
        desc: 'fläskfilé, fetaost, lök, oliver, paprika, pepperoni, färska tomater',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 67,
        name: 'staffaren ́s',
        desc: 'salami, bacon, pepperonikorv, ägg, lök',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 68,
        name: 'acapulco',
        desc: 'oxfilé, köttfärs, champinjoner, lök, jalapeño, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 69,
        name: 'bagaren ́s special',
        desc: 'oxfilé, champinjoner, lök, paprika, persilja, tomat, cayennepeppar, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 70,
        name: 'ciao ciao (halvinbakad)',
        desc: 'oxfilé, champinjoner, tomater, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 71,
        name: 'sh ́s special',
        desc: 'fläskfilé, gorgonzola, lök, bacon, champinjoner, jalapeño, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 72,
        name: 'picasso',
        desc: 'fläskfilé, bacon, salami, lök, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 73,
        name: 'black & white',
        desc: 'oxfilé, fläskfilé, lök, färska tomater, champinjoner, bearnaisesås',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 74,
        name: 'kebabpizza med pommes',
        desc: 'kebabkött, lök, tomat, pepperoni, kebabsås, pommes',
        price: 135,
        takeawayPrice: 110,
      },
      {
        id: 75,
        name: 'dubbel calzone',
        desc: 'salami, bacon, pepperonikorv, ägg, lök',
        price: 135,
        takeawayPrice: 110,
      },
    ],
  },
  {
    category: 'pizza',
    subCategory: 'pizza klass 6',
    description: '',
    takeawayDesc: 'vi bakar traditionell surdegspizza!',
    takeawayDesc2: 'vegansk ost +20:-',
    takeawayDesc3: 'glutenfri pizzabotten +20:-',
    categoryId: 111,
    items: [
      {
        id: 76,
        name: 'amore',
        desc: 'kyckling, mozzarella, pesto, ruccola, soltorkade tomater, solrosfrön, färsk basilika, kallpressad olivolja',
        price: 155,
        takeawayPrice: 130,
      },
      {
        id: 77,
        name: 'berra ́s',
        desc: 'lufttorkad skinka, mozzarella, pesto, färsk basilika, soltorkade tomater, oliver, parmesan, kallpressad olivolja',
        price: 155,
        takeawayPrice: 130,
      },
      {
        id: 78,
        name: 'napoli',
        desc: 'lufttorkad skinka, mozzarella, pinjenötter, soltorkade tomater, ruccola, parmesan, kallpressad olivolja',
        price: 155,
        takeawayPrice: 130,
      },
    ],
  },
  {
    category: 'varmrätter',
    subCategory: 'barnmeny',
    description: 'för barn under 12 år',
    categoryId: 112,
    items: [
      {
        id: 79,
        name: 'kycklingspett',
        desc: 'med pommes eller ris',
        price: 119,
      },
      {
        id: 80,
        name: 'vesuvio',
        desc: '',
        price: 95,
      },
      {
        id: 82,
        name: 'pannkakor',
        desc: 'med grädde, sylt & nutella',
        price: 95,
      },
    ],
  },
  {
    category: 'dessert',
    subCategory: 'dessert',
    description: '',
    categoryId: 113,
    items: [
      {
        id: 33,
        name: 'baklava med vaniljglass',
        desc: '',
        price: 79,
      },
      {
        id: 34,
        name: 'kunefe',
        desc: 'mozarellaost & vaniljglass',
        price: 89,
      },
      {
        id: 35,
        name: 'dagens sorbet',
        desc: '',
        price: 79,
      },
    ],
  },
];

export default data;
